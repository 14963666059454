@import "bootstrap/bootstrap";
@import "for";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
@color-elemental-evil: #A3FF75;
@color-homebrew:#c47b31;
@color-ritual:#FFFF99;
//green:    #A3FF75
//red:      #e16c5b
//blue:     #CCFFFF
//brown:    #c47b31
//yellow:   #FFFF99
@schools: abjuration, conjuration, divination, enchantment, evocation, illusion, necromancy, transmutation;
@classes: barbarian, bard, cleric, druid, fighter, monk, paladin, ranger, rogue, sorcerer, warlock, wizard;

body {
    -webkit-animation-delay: 0.1s;
    -webkit-animation-name: fontfix;
    -webkit-animation-duration: 0.1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
    padding-top: 70px;
}

@-webkit-keyframes fontfix {
    from { opacity: 1; }
    to   { opacity: 1; }
}

body, label, .checkbox label {
	font-weight: 300;
}

.show {
    opacity: 1;
}

.ui-autocomplete {
	z-index: 1200;
}

nav {
    .elemental-evil {
        color: @color-elemental-evil;
    }
}

.panel-heading {
	.panel-title {
		display:inline;
        line-height: 20px;
	}

	i {
		font-size:12px;
		padding-left:10px;
	}

	.filter-toggle {
		float:right;
		color:inherit;
	}

	&.filters {
		/*height:0px;
		position: relative;
		top: -1px;
		&.open*/
		padding: 0;
		ul {
			margin:0;
			padding:10px 15px;
			list-style: none;

			li {
				padding:0 8px;
				display: inline;
				white-space: nowrap;

				input[type="checkbox"], label{
					cursor: pointer;
				}

				label{
					padding-left:5px;
					-webkit-user-select: none;  /* Chrome all / Safari all */
					-moz-user-select: none;     /* Firefox all */
					-ms-user-select: none;      /* IE 10+ */
					-o-user-select: none;
					user-select: none;
				}
			}
		}
	}
}

.list-items {
	padding:0;
	.list-item {
		margin-left:20px;

		.via {
			border:solid 1px #dddddd;
			border-radius:3px;
			padding:1px 5px;
			font-size: 13px;
			margin-left:7px;
		}

        .note {
            margin-left:5px;
            font-size: 12px;
            border-radius: 3px;
            padding: 2px 6px;
            &.elemental-evil {
                background: @color-elemental-evil;
            }
            &.homebrew {
                background: @color-homebrew;
            }
            &.ritual {
    			background:@color-ritual;
    		}
        }

		.additional {
			li:last-child{
				margin-bottom: 15px;
			}
		}
	}
}

.entry-page {
    .panel-heading {
        .expansion {
            border-radius: 3px;
            font-size: 12px;
            display: block;
            float:right;
            padding: 2px 6px;

            &.elemental-evil {
                background: @color-elemental-evil;
            }

            &.homebrew {
                background: @color-homebrew;
            }

            a {
                color: inherit;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

	.classes {
		.class {
			display:block;
			float:left;
			margin:0 10px 20px;

			a {
				display: block;
				border-radius: 3px;
				border:solid 1px #dddddd;
				padding:5px 15px;
				color:#000000;

                &:hover {
                    text-decoration: none;
                    border-color: #666666;
                }
			}

			.via {
				font-size: 12px;
			}
		}
	}

    .entry {
        #monster-image {
            margin-bottom: 20px;
            max-width: 100%;

            @media only screen and (min-width : 768px) {
                float: right;
                margin-left: 20px;
                max-width: calc(~"50% - 20px");
            }
        }

        h2 {
            font-size: 20px;
            margin-top: 10px;
        }

        h3 {
            font-size: 18px;
            margin-top: 10px;
        }

        h4 {
            font-size: 16px;
        }

        a {
			font-style: italic;
			text-decoration: none;
			color: inherit;
			&:hover{
				text-decoration: underline;
			}
		}

        .school-link {
    		color: inherit;
    	}

    	.description {
    		margin:20px;

            h4 {
                margin-top: 20px;
            }

    		p {
    			margin-bottom:1em;
    		}

    		table {
    			margin-bottom:1em;
    			tr {
    				th {
    					padding:2px 10px;
    					&:not(:first-child) {
    						text-align: center;
    					}
    				}

    				td {
    					padding:1px 10px;
    					&:not(:first-child) {
    						text-align: center;
    					}
    				}
    			}
    			&.inverse-alignment {
    				tr {
    					th {
    						text-align:left;
    						&:first-child {
    							text-align: center;
    						}
    					}

    					td {
    						text-align:left;
    						&:first-child {
    							text-align: center;
    						}
    					}
    				}
    			}
    		}
    	}
    }
}

.ability-scores {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;

    b {
        display:block;
    }
}

.spell_add_form, .tome_remove_form {
    button.glyphicon {
        border: 0px;
        background: none;
        outline: none;
    }
}

.panel-heading .tome_remove_form {
    margin-top: -7px;
}

.panel-body.tomes {
    ul {
        margin-bottom: 20px;
    }
    ul, li {
        list-style: none;
        margin-left: 0px;
        padding-left: 0px;
    }
    span {
        font-size: 18px;
        font-weight: 500;

        > em {
            padding-left: 10px;
            font-size: 12px;
        }
    }
}


.symbol {
    display: inline-block;
    position: relative;
    margin-right: 25px;

    &:after {
        display: block;
        content: " ";
        min-width: 27px;
        height: calc(~"100% + 4px");
        background-color: #333333;
        -webkit-mask-size: auto 100%;
                mask-size: auto 100%;
        -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
        -webkit-mask-position: center center;
                mask-position: center center;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);

        .navbar-default .navbar-nav .open .dropdown-menu > li > a > & {
            background-color: #777777;
        }

        .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover > &,
        .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus > & {
            background-color: #333333;
        }
    }
    .for(@classes); .-each(@name) {
        &.class-symbol-@{name} {
            &:after {
                -webkit-mask-image: url("/icons/classes/light/@{name}.svg");
                        mask-image: url("/icons/classes/light/@{name}.svg");
            }
        }
    }
    .for(@schools); .-each(@name) {
        &.school-symbol-@{name} {
            &:after {
                -webkit-mask-image: url("/icons/schools/@{name}.svg");
                        mask-image: url("/icons/schools/@{name}.svg");
            }
        }
    }
}
